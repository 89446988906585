import DirectionProvider from '@veneer/core/dist/esm/scripts/direction/direction_provider'
import React, { useEffect, useState } from 'react'
import {
  InterfacesType,
  ShellRootComponentProps,
  TranslatorFunctionType
} from 'src/types/shell'
import { ShellRootProvider } from 'src/contexts/ShellRoot'
import naming from '../src/config/naming.js'
import resources from 'src/assets/locale'
import { findLocaleFallback } from '../src/utils/helpers'
import { ThemeProvider } from '@veneer/theme'
import {
  useMultiUiBrandContext,
  getThemeProviderConfig
} from '@jarvis/react-setup-and-onboarding-addons'

export default function Root({
  useToast,
  properties,
  onMount,
  onUnmount,
  appComponent
}: ShellRootComponentProps) {
  const { uiBrand } = useMultiUiBrandContext()
  const [t, setT] = useState<TranslatorFunctionType>()
  // You can find all Shell´s props here. See "src/types/shell" for more details.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { v1, v2 } = (window as any).Shell as InterfacesType
  const interfaces = {
    v1,
    v2
  }
  const direction =
    v1?.localization?.useReactGetLanguageDirection?.(React) || 'auto'

  useEffect(() => {
    if (typeof onMount === 'function') {
      onMount?.()
    }

    return () => {
      if (typeof onUnmount === 'function') {
        onUnmount?.()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Create a translator function by providing the resource files
  useEffect(() => {
    const country = v1?.localization?.country || 'US'
    const language = v1?.localization?.language || 'en'
    const options = {
      fallbackLng: [findLocaleFallback(language, country)],
      ensureFallbackLng: true
    }
    v1.localization
      .createTranslatorFunction(resources, options)
      .then((v) => setT(() => v))
  }, [v1])

  // Wait for the translator function creation to show the content
  if (!t) {
    return null
  }

  return (
    <section className={naming.namespace} id={naming.packageJsonName}>
      <DirectionProvider
        direction={direction}
        locale={v1?.localization?.locale}
      >
        <ThemeProvider {...getThemeProviderConfig(uiBrand)}>
          <ShellRootProvider {...{ interfaces, t, useToast, properties }}>
            {appComponent}
          </ShellRootProvider>
        </ThemeProvider>
      </DirectionProvider>
    </section>
  )
}
