import React, { useCallback, useMemo, useRef } from 'react'
import { EMPTY_FUNCTION, STATUS_COMPLETED } from '../../src/config/constants'

export const ActionContext = React.createContext({
  isCurrentActionAuto: true,
  findNextPage: EMPTY_FUNCTION,
  state: []
})

const ActionProvider = (props) => {
  const actionsToExecute = useRef(['languageCountrySelector'])

  const findNextPage = useCallback(async () => {
    const nextAction = actionsToExecute.current.shift()
    if (!nextAction) return `/${STATUS_COMPLETED}`
    return `/${nextAction}`
  }, [])

  const state = useMemo(
    () => ({
      findNextPage,
      isCurrentActionAuto: true,
      state: []
    }),
    [findNextPage]
  )

  return (
    <ActionContext.Provider value={state}>
      {props.children}
    </ActionContext.Provider>
  )
}

export default ActionProvider
