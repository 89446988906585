import React, { useContext } from 'react'
import classes from '../../pages/Completed.module.scss'
import ProgressIndicator from '@veneer/core/dist/esm/scripts/progress_indicator/progress_indicator'
import { ConfigContext } from '../../store/ConfigContext'
import {
  UiTheme,
  useMultiUiBrandContext
} from '@jarvis/react-setup-and-onboarding-addons'

export const FullscreenProgress = (props) => {
  const { text } = props
  const { direction } = useContext(ConfigContext)
  const { uiBrand } = useMultiUiBrandContext()

  return (
    <>
      <div
        className={`${classes.completed} ${classes.spinner}`}
        dir={direction}
      >
        <div
          className={
            uiBrand === UiTheme.hpx
              ? classes.progressBarHpx
              : classes.progressBar
          }
        >
          <ProgressIndicator
            data-testid="spinner"
            appearance="circular"
            behavior="indeterminate"
          />
          {text && <label className={classes.label}>{text}</label>}
        </div>
      </div>
    </>
  )
}
