import {
  STATUS_COMPLETED,
  LANGUAGE_CONFIG,
  COUNTRY_CONFIG,
  STATUS_PENDING,
  SUPPORTED_LEDM_ACTIONS,
  languageDisplayName,
  countryDisplayName,
  LOAD_MAIN_TRAY,
  INSTALL_PHA,
  STATUS_GENERIC_FAILURE,
  STATUS_FAILED,
  RESULT,
  SEMI_CALIBRATION_PRINT,
  STATUS_IN_PROGRESS,
  FILL_INK_TANKS,
  SEMI_CALIBRATION_SCAN,
  INSERT_INK,
  _IGNORABLE_CALIBRATION_ALERTS,
  _KNOW_CALIBRATION_ALERTS,
  _KNOW_PHOTO_TRAY_ALERTS,
  PHOTO_TRAY,
  _IGNORABLE_CARTRIDGE_ALERTS,
  _KNOW_CARTRIDGE_ALERTS,
  RESOURCE_URI_FALLBACK,
  REMOVE_WRAP,
  CALIBRATION,
  STATUS_NOT_STARTED,
  LIVE_UI_VERSION_1,
  LIVE_UI_VERSION_2,
  TRANSITION_TYPE_MANUAL
} from '../../config/constants'

import {
  sortOnKeys,
  Logger,
  exportActionStateToList,
  returnAlertByPriority,
  parseAlertByPriority
} from '../helpers'

const { LEDM: URI } = RESOURCE_URI_FALLBACK

const getUnsupportedActions = (oobeStatus) => {
  const unsupportedActions = []
  for (const stage of oobeStatus?.['ob:OOBEStatus']?.['ob:OOBEStage']) {
    if (!SUPPORTED_LEDM_ACTIONS.includes(stage['ob:Name'])) {
      unsupportedActions.push({
        name: stage['ob:Name'],
        result: RESULT.INELIGIBLE
      })
    }
  }
  return unsupportedActions
}

const getAlreadyPerformedActions = (oobeStatus) => {
  const supportedAndAlreadyPerformedActions = []
  for (const stage of oobeStatus?.['ob:OOBEStatus']?.['ob:OOBEStage']) {
    if (
      SUPPORTED_LEDM_ACTIONS.includes(stage['ob:Name']) &&
      stage['ob:State'] &&
      stage['ob:State'] === STATUS_COMPLETED
    ) {
      supportedAndAlreadyPerformedActions.push({
        name: stage['ob:Name'],
        result: RESULT.ALREADY_PERFORMED
      })
    }
  }
  return supportedAndAlreadyPerformedActions
}

export const findIneligibleOrAlreadyPerformedActions = (oobeStatus) => {
  return [
    ...getUnsupportedActions(oobeStatus),
    ...getAlreadyPerformedActions(oobeStatus)
  ]
}

export const getNextActionBySuggestedOrder = (
  oobeStatus,
  liveUiVersion,
  actionState
) => {
  let suggestedOrder = {}

  const actionsToSuppress = exportActionStateToList(actionState)

  const actionPairs = {
    [LANGUAGE_CONFIG]: COUNTRY_CONFIG,
    [COUNTRY_CONFIG]: LANGUAGE_CONFIG,
    [SEMI_CALIBRATION_SCAN]: SEMI_CALIBRATION_PRINT
  }

  Object.entries(actionPairs).forEach(([action, relatedAction]) => {
    if (actionsToSuppress.includes(action)) {
      actionsToSuppress.push(relatedAction)
    }
  })

  if (oobeStatus?.['ob:OOBEStatus']?.['ob:OOBECompleted']) {
    return { name: STATUS_COMPLETED }
  }

  for (const stage of oobeStatus?.['ob:OOBEStatus']?.['ob:OOBEStage']) {
    if (
      stage['ob:State'] !== STATUS_COMPLETED &&
      !actionsToSuppress.includes(stage['ob:Name']) &&
      SUPPORTED_LEDM_ACTIONS.includes(stage['ob:Name']) && // process only if a supported action
      !_canBeSkipped(stage, oobeStatus, liveUiVersion, actionState)
    ) {
      suggestedOrder[stage['ob:Order']] = stage
    }
  }
  if (!Object.keys(suggestedOrder).length) {
    return { name: STATUS_COMPLETED }
  }

  suggestedOrder = sortOnKeys(suggestedOrder)
  const action = suggestedOrder[Object.keys(suggestedOrder)[0]]
  return { ...action, name: action['ob:Name'] }
}

const _canBeSkipped = (stage, oobeStatus, liveUiVersion, actionState) => {
  switch (stage['ob:Name']) {
    case LOAD_MAIN_TRAY:
      return _canLoadMainTrayBeSkipped(oobeStatus, liveUiVersion)
    case CALIBRATION:
    case SEMI_CALIBRATION_PRINT:
    case SEMI_CALIBRATION_SCAN:
      return _canCalibrationBeSkipped(oobeStatus, actionState, liveUiVersion)
    default:
      return false
  }
}

const _findStage = (name, oobeStatus) => {
  return oobeStatus?.['ob:OOBEStatus']?.['ob:OOBEStage'].find(
    (stage) => stage['ob:Name'] === name
  )
}

const _isAutoTransitionTypeStage = (stage) => {
  return stage?.['ob:StateTransitionType'] !== TRANSITION_TYPE_MANUAL
}

const _canLoadMainTrayBeSkipped = (oobeStatus, liveUiVersion) => {
  const loadMainTrayStage = _findStage(LOAD_MAIN_TRAY, oobeStatus)
  const calibrationStage =
    _findStage(CALIBRATION, oobeStatus) ||
    _findStage(SEMI_CALIBRATION_PRINT, oobeStatus)
  return (
    _isAutoTransitionTypeStage(loadMainTrayStage) &&
    calibrationStage &&
    (calibrationStage?.['ob:State'] !== STATUS_NOT_STARTED ||
      (liveUiVersion === LIVE_UI_VERSION_1 &&
        loadMainTrayStage?.['ob:State'] === STATUS_FAILED))
  )
}

const _canCalibrationBeSkipped = (oobeStatus, actionState, liveUiVersion) => {
  if (
    liveUiVersion === LIVE_UI_VERSION_2 &&
    _isAutoTransitionTypeCalibration(oobeStatus)
  ) {
    const skippedStages = Object.values(actionState).filter(
      (item) =>
        item.result === RESULT.DECLINED || item.result === RESULT.FAILURE
    )
    const stages = Object.values(skippedStages).map((item) => item.name)
    return (
      stages.includes(FILL_INK_TANKS) ||
      stages.includes(INSERT_INK) ||
      stages.includes(LOAD_MAIN_TRAY) ||
      stages.includes(INSTALL_PHA)
    )
  }
  return false
}

const _isAutoTransitionTypeCalibration = (oobeStatus) => {
  const calibration = _findStage(CALIBRATION, oobeStatus)
  const semiCalibrationPrint = _findStage(SEMI_CALIBRATION_PRINT, oobeStatus)
  const semiCalibrationScan = _findStage(SEMI_CALIBRATION_SCAN, oobeStatus)

  return (
    (calibration && _isAutoTransitionTypeStage(calibration)) ||
    (semiCalibrationPrint &&
      _isAutoTransitionTypeStage(semiCalibrationPrint)) ||
    (semiCalibrationScan && _isAutoTransitionTypeStage(semiCalibrationScan))
  )
}

const getResourceUri = (discoveryTree, type) => {
  try {
    return discoveryTree['ledm:DiscoveryTree']['ledm:SupportedTree'].find(
      (service) => service['dd:ResourceType'] === type
    )['dd:ResourceURI']
  } catch (e) {
    let log = `Not able to find resource uri for ${type} type - ${e}`
    if (discoveryTree) log += ` - ${JSON.stringify(discoveryTree)}`
    Logger.warn(log)
    return null
  }
}

const getManifestUri = (discoveryTree, type) => {
  try {
    return discoveryTree['ledm:DiscoveryTree']['ledm:SupportedIfc'].find(
      (service) => service['dd:ResourceType'] === type
    )['ledm:ManifestURI']
  } catch (e) {
    let log = `Not able to find manifest uri for ${type} type - ${e}`
    if (discoveryTree) log += ` - ${JSON.stringify(discoveryTree)}`
    Logger.warn(log)
    return null
  }
}

export const getProductConfigDynHref = (discoveryTree) => {
  return (
    getResourceUri(discoveryTree, 'ledm:hpLedmProductConfigDyn') ||
    URI.productConfigDyn
  )
}

export const getProductConfigCapHref = (discoveryTree) => {
  return (
    getResourceUri(discoveryTree, 'ledm:hpLedmProductConfigCap') ||
    URI.productConfigCap
  )
}

export const getProductStatusDynHref = (discoveryTree) => {
  return (
    getResourceUri(discoveryTree, 'ledm:hpLedmProductStatusDyn') ||
    URI.productStatusDyn
  )
}

export const getCalibrationManifestHref = (discoveryTree) => {
  return (
    getManifestUri(discoveryTree, 'ledm:hpCnxCalibrationManifest') ||
    URI.calibrationManifest
  )
}

const getResourceHref = (resourceManifest, resourceKey, resourceType) => {
  try {
    const basePath =
      resourceManifest['man:Manifest']['map:ResourceMap']['map:ResourceLink'][
        'dd:ResourceURI'
      ]
    const calibrationStateUri = resourceManifest['man:Manifest'][
      'map:ResourceMap'
    ]['map:ResourceNode'].find(
      (resource) => resource['map:ResourceType'][resourceKey] === resourceType
    )['map:ResourceLink']['dd:ResourceURI']
    return basePath + calibrationStateUri
  } catch (e) {
    let log = `Not able to find ${resourceKey} == ${resourceType} - ${e}`
    if (resourceManifest) log += ` - ${JSON.stringify(resourceManifest)}`
    Logger.warn(log)
    return null
  }
}

const getCalibrationHref = (calibrationManifest, resourceType) => {
  return getResourceHref(
    calibrationManifest,
    'cal:CalibrationType',
    resourceType
  )
}

export const getCalibrationSessionHref = (calibrationManifest) => {
  return (
    getCalibrationHref(calibrationManifest, 'CalibrationSession') ||
    URI.calibrationSession
  )
}

export const getCalibrationStateHref = (calibrationManifest) => {
  return (
    getCalibrationHref(calibrationManifest, 'CalibrationState') ||
    URI.calibrationState
  )
}

export const getOobeManifestHref = (discoveryTree) => {
  return (
    getManifestUri(discoveryTree, 'ledm:hpOOBEManifest') || URI.oobeManifest
  )
}

const getOobeHref = (manifestTree, resourceType) => {
  return getResourceHref(manifestTree, 'ob:OOBEResourceType', resourceType)
}

export const getOobeStatusHref = (manifestTree) => {
  return getOobeHref(manifestTree, 'Status') || URI.oobeStatus
}

export const getOobeConfigHref = (manifestTree) => {
  return getOobeHref(manifestTree, 'Config') || URI.oobeConfig
}

export const getOobeDeviceInfoHref = (manifestTree) => {
  return getOobeHref(manifestTree, 'DeviceInfo') || URI.oobeDeviceInfo
}

const getActionStatus = (oobeStatus, stageName, stateParser) => {
  const stages = oobeStatus?.['ob:OOBEStatus']?.['ob:OOBEStage'] || []
  for (const stg of stages) {
    if (stg['ob:Name'] === stageName) {
      return stateParser(stg['ob:State'])
    }
  }
  return STATUS_PENDING
}

const parseStateForCompletedOrPending = (state) => {
  if (state === STATUS_COMPLETED) return STATUS_COMPLETED
  return STATUS_PENDING
}

export const getLoadMainTrayActionStatus = (oobeStatus) => {
  return getActionStatus(
    oobeStatus,
    LOAD_MAIN_TRAY,
    parseStateForCompletedOrPending
  )
}

export const getLanguageConfigStatus = (oobeStatus) => {
  return getActionStatus(
    oobeStatus,
    LANGUAGE_CONFIG,
    parseStateForCompletedOrPending
  )
}

export const getCountryConfigStatus = (oobeStatus) => {
  return getActionStatus(
    oobeStatus,
    COUNTRY_CONFIG,
    parseStateForCompletedOrPending
  )
}

export const getSemiCalibrationPrintStatus = (oobeStatus) => {
  const _parseState = (state) => {
    if (
      [STATUS_COMPLETED, STATUS_IN_PROGRESS, STATUS_FAILED].indexOf(state) !==
      -1
    )
      return state
    return STATUS_PENDING
  }
  return getActionStatus(oobeStatus, SEMI_CALIBRATION_PRINT, _parseState)
}

const _getStatusFromState = (state) => {
  return [STATUS_COMPLETED, STATUS_IN_PROGRESS, STATUS_FAILED].includes(state)
    ? state
    : STATUS_PENDING
}

export const getAutoCalibrationStatus = (oobeStatus) => {
  const _parseState = (state) => {
    return _getStatusFromState(state)
  }
  return getActionStatus(oobeStatus, CALIBRATION, _parseState)
}

export const getSemiCalibrationScanStatus = (oobeStatus) => {
  const _parseState = (state) => {
    return _getStatusFromState(state)
  }
  return getActionStatus(oobeStatus, SEMI_CALIBRATION_SCAN, _parseState)
}

export const getInsertInkStatus = (oobeStatus) => {
  const _parseState = (state) => {
    return _getStatusFromState(state)
  }
  return getActionStatus(oobeStatus, INSERT_INK, _parseState)
}

const _getInkTanksOrPhaState = (state) => {
  if (state === STATUS_COMPLETED) return STATUS_COMPLETED
  else if (state === STATUS_GENERIC_FAILURE) return STATUS_FAILED
  return STATUS_PENDING
}

export const getInstallPhaActionStatus = (oobeStatus) => {
  return getActionStatus(oobeStatus, INSTALL_PHA, _getInkTanksOrPhaState)
}

export const getFillInkTanksActionStatus = (oobeStatus) => {
  return getActionStatus(oobeStatus, FILL_INK_TANKS, _getInkTanksOrPhaState)
}

export const getRemoveWrapStatus = (oobeStatus) => {
  return getActionStatus(
    oobeStatus,
    REMOVE_WRAP,
    parseStateForCompletedOrPending
  )
}

export const getLanguage = (productConfigDyn) => {
  try {
    const language =
      productConfigDyn['prdcfgdyn2:ProductConfigDyn'][
        'prdcfgdyn2:ProductSettings'
      ]['prdcfgdyn:ProductLanguage']['dd:DeviceLanguage']
    return languageDisplayName[language]
  } catch (e) {
    Logger.warn(`Not able to get device language - ${e}`)
  }
  return null
}

export const getCountry = (productConfigDyn) => {
  try {
    const country =
      productConfigDyn['prdcfgdyn2:ProductConfigDyn'][
        'prdcfgdyn2:ProductSettings'
      ]['prdcfgdyn:CountryAndRegionName']
    return countryDisplayName[country]
  } catch (e) {
    Logger.warn(`Not able to get device country - ${e}`)
  }
  return null
}
export const getSupportedLanguages = (productConfigCap) => {
  try {
    return productConfigCap['prdcfgcap2:ProductConfigCap'][
      'prdcfgcap2:ProductSettingsCap'
    ]['prdcfgcap:LanguageSupport']['dd:Language']
  } catch (e) {
    Logger.warn(`Not able to get supported languages - ${e}`)
  }
  return []
}

export const getSupportedCountries = (productConfigCap) => {
  try {
    return productConfigCap['prdcfgcap2:ProductConfigCap'][
      'prdcfgcap2:ProductSettingsCap'
    ]['prdcfgcap:CountryAndRegionNameSupport']['prdcfgdyn:CountryAndRegionName']
  } catch (e) {
    Logger.warn(`Not able to get supported countries - ${e}`)
  }
  return []
}

export const filterCalibrationAlerts = (alerts) => {
  return filterAlertsBySeverityAndPriority(
    alerts,
    _KNOW_CALIBRATION_ALERTS,
    _IGNORABLE_CALIBRATION_ALERTS,
    _KNOW_PHOTO_TRAY_ALERTS,
    false
  )
}

export const filterInsertInkAlerts = (alerts) => {
  return filterAlertsBySeverityAndPriority(
    alerts,
    _KNOW_CARTRIDGE_ALERTS,
    _IGNORABLE_CARTRIDGE_ALERTS,
    [],
    true
  )
}

export const filterAlertsBySeverityAndPriority = (
  alerts,
  known_alerts,
  ignorable_alerts,
  photo_tray_alerts,
  is_ink_alert
) => {
  let highestPriorityAlert = []
  let highestPriorityError = []
  let cartridges = []

  const alertsArray = Array.isArray(alerts) ? alerts : [alerts]

  alertsArray.forEach((alertElement) => {
    const alert = {
      StringId: alertElement?.['locid:StringId'],
      Severity: alertElement?.['ad:Severity'],
      Priority: alertElement?.['ad:AlertPriority'],
      Cartridge:
        alertElement?.['ad:AlertDetails']?.['ad:AlertDetailsMarkerColor'],
      InputBin: alertElement?.['ad:AlertDetails']?.['ad:AlertDetailsInputBin']
    }

    const { StringId, InputBin } = alert

    if (photo_tray_alerts[StringId] && InputBin === PHOTO_TRAY) return

    parseAlertByPriority(
      alert,
      ignorable_alerts,
      is_ink_alert,
      highestPriorityAlert,
      known_alerts,
      cartridges,
      highestPriorityError
    )
  })

  return returnAlertByPriority(
    highestPriorityAlert,
    is_ink_alert,
    known_alerts,
    highestPriorityError,
    cartridges
  )
}
