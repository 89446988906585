import {
  generateError,
  getLanguageCode,
  getCountryIsoByDisplayName,
  getDisplayName,
  isDHPError
} from '../utils/helpers'
import {
  getCalibrationCapabilitiesHref,
  getReportsPrintHref,
  getSuppliesPublicHref,
  getSystemConfigHref,
  getSystemConfigConstraintsHref,
  getPackageInformationHref,
  getCartridgeSetupHref,
  getLanguage,
  getCountry,
  getSupportedLanguages,
  getSupportedCountries,
  getCalibrationAlertsHref,
  getCartridgeAlertsHref
} from '../utils/cdm/helpers'
import { useContext } from 'react'
import { ConfigContext } from '../store/ConfigContext'
import { ErrorContext } from '../store/ErrorContext'
import {
  countryIsoDisplayName,
  languageDisplayName,
  SERVICE_ID,
  STATE
} from '../config/constants'
import { ActionContext } from '../store/ActionContext'
import { LoggingContext } from '../store/LoggingContext'

const useCdm = () => {
  const {
    sessionId,
    sendDHPRequest: plugin,
    isPreview
  } = useContext(ConfigContext)
  const { setError } = useContext(ErrorContext)
  const { currentAction } = useContext(ActionContext)
  const { Logger } = useContext(LoggingContext)

  const _handleRequest = async (payload, handleError, useCache = false) => {
    Logger.log(`Fetching DHP CDM - payload ${JSON.stringify(payload)}`)
    const response = isPreview ? {} : await plugin(payload, useCache)

    const payloadData = payload?.body?.data ? payload.body.data : null
    const payloadPath = payload.path

    if (!isPreview && handleError && isDHPError(response)) {
      setError(
        generateError({
          ...response,
          payload: payloadData,
          resourceUri: payloadPath
        })
      )
      return null
    }

    if (response?.body?.data) {
      const data = response.body.isBase64Encoded
        ? atob(response.body.data)
        : response.body.data
      try {
        response.body.data = JSON.parse(data)
      } catch (e) {
        if (handleError) {
          setError(
            generateError({
              errorType: 'unableToDecode',
              reason: e,
              payload: payloadData,
              resourceUri: payloadPath
            })
          )
          return null
        }
      }
    }

    Logger.log(`Fetched DHP CDM - response ${JSON.stringify(response)}`)
    return response
  }

  const fetch = async (href, handleError = true, useCache = false) => {
    const options = {
      path: href,
      sessionId: sessionId,
      method: 'GET',
      calledBy: SERVICE_ID
    }
    return _handleRequest(options, handleError, useCache)
  }

  const patch = async (href, payload, handleError = true) => {
    const options = {
      path: href,
      sessionId: sessionId,
      method: 'PATCH',
      body: payload,
      allowUserInteraction: true,
      calledBy: SERVICE_ID
    }
    return _handleRequest(options, handleError)
  }

  const fetchCalibrationCapabilities = async () => {
    const href = getCalibrationCapabilitiesHref(currentAction)
    return fetch(href)
  }

  const fetchCalibrationAlerts = async (reportsPrint) => {
    const href = getCalibrationAlertsHref(reportsPrint)
    return fetch(href)
  }

  const fetchReportsPrint = async () => {
    const href = getReportsPrintHref(currentAction)
    return fetch(href)
  }

  const fetchServicesDiscovery = async () => {
    return fetch('/cdm/servicesDiscovery', true, true)
  }

  const fetchSuppliesPublic = async () => {
    const href = getSuppliesPublicHref(currentAction)
    return fetch(href)
  }
  const fetchCartridgeSetup = async () => {
    const href = getCartridgeSetupHref(currentAction)
    return fetch(href)
  }

  const fetchSystemConfig = async () => {
    const href = getSystemConfigHref(currentAction)
    return fetch(href, true, true)
  }

  const fetchSystemConfigConstraints = async (systemConfig) => {
    const href = getSystemConfigConstraintsHref(systemConfig)
    return fetch(href, true, true)
  }

  const fetchPackageInformation = async () => {
    const href = getPackageInformationHref(currentAction)
    return fetch(href)
  }

  const fetchCartridgeSetupAlerts = async (cartridgeSetup) => {
    const href = getCartridgeAlertsHref(cartridgeSetup)
    return fetch(href)
  }

  const patchCalibrationCapabilities = async (
    calibrationType,
    operationType,
    state
  ) => {
    const href = getCalibrationCapabilitiesHref(currentAction)
    const payload = {
      data: `{"calibrationType": "${calibrationType}", "operationType": "${operationType}", "state": "${state}"}`
    }
    return patch(href, payload)
  }

  const patchDeviceSetup = async (href, payload) => {
    const data = {
      data: JSON.stringify(payload)
    }
    return patch(href, data)
  }
  const patchCartridgeSetupState = async (state) => {
    const href = getCartridgeSetupHref(currentAction)
    const payload = {
      [STATE]: state
    }
    const data = {
      data: JSON.stringify(payload)
    }
    return patch(href, data)
  }

  const patchReportsPrint = async (reportId, state) => {
    const href = getReportsPrintHref(currentAction)
    const payload = {
      data: `{"reportId": "${reportId}", "state": "${state}"}`
    }
    return patch(href, payload, false)
  }

  const patchSystemConfig = async (language, country, handleError = true) => {
    const href = getSystemConfigHref(currentAction)
    const payload = {
      data: `{"deviceLanguage": "${getLanguageCode(
        language
      )}", "countryRegion": "${getCountryIsoByDisplayName(country)}"}`
    }
    return patch(href, payload, handleError)
  }

  const getLocale = async () => {
    const result = {
      language: null,
      country: null,
      supportedLanguages: [],
      supportedCountries: [],
      supportedLanguagesDisplayName: [],
      supportedCountriesDisplayName: []
    }

    let response = await fetchSystemConfig()
    const systemConfig = response?.body?.data
    result.language = getLanguage(systemConfig)
    result.country = getCountry(systemConfig)
    response = await fetchSystemConfigConstraints(systemConfig)
    const systemConfigConstraints = response?.body?.data
    result.supportedLanguages = getSupportedLanguages(systemConfigConstraints)
    result.supportedLanguagesDisplayName = getDisplayName(
      result.supportedLanguages,
      languageDisplayName
    )
    result.supportedCountries = getSupportedCountries(systemConfigConstraints)
    result.supportedCountriesDisplayName = getDisplayName(
      result.supportedCountries,
      countryIsoDisplayName
    )

    return result
  }

  return {
    fetch,
    patch,
    fetchCalibrationCapabilities,
    fetchCalibrationAlerts,
    fetchReportsPrint,
    fetchServicesDiscovery,
    fetchSuppliesPublic,
    fetchSystemConfig,
    fetchSystemConfigConstraints,
    patchCalibrationCapabilities,
    patchDeviceSetup,
    patchReportsPrint,
    patchSystemConfig,
    fetchPackageInformation,
    fetchCartridgeSetup,
    fetchCartridgeSetupAlerts,
    patchCartridgeSetupState,
    getLocale
  }
}

export default useCdm
