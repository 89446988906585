import React, { useContext, useEffect, useState, useCallback } from 'react'
import { ConfigContext } from '../../src/store/ConfigContext'
import {
  ANALYTICS,
  LIVE_UI_VERSION_2,
  RESULT
} from '../../src/config/constants'
import useAnalytics from '../../src/hooks/useAnalytics'
import { FullscreenProgress } from '../../src/components/LiveUi/FullscreenProgress'
import { PrinterContext } from '../store/PrinterContext'
import { ActionContext } from '../store/ActionContext'
import { useNavigate } from 'react-router-dom'
import { isLanguageCountrySupportedAndNotCompleted as isLanguageCountryStatusValidCdm } from '../utils/cdm/helpers'
import { isLanguageCountrySupportedAndNotCompleted as isLanguageCountryStatusValidLedm } from '../utils/ledm/helpers'

const { SCREENS } = ANALYTICS

const PortalEntry = () => {
  const navigate = useNavigate()
  const [printerInitiated, setPrinterInitiated] = useState(false)
  const [nextPageLoaded, setNextPageLoaded] = useState(false)
  const {
    sessionId,
    init: configInit,
    closeServiceInstance
  } = useContext(ConfigContext)
  const {
    init: printerInit,
    isSupported,
    liveUiVersion,
    status,
    isLedm
  } = useContext(PrinterContext)
  const { findNextPage } = useContext(ActionContext)
  const { fireScreenDisplayed } = useAnalytics(
    SCREENS.START_LANGUAGE_COUNTRY,
    true,
    PrinterContext
  )
  fireScreenDisplayed()

  useEffect(() => {
    if (!isSupported) {
      closeServiceInstance(RESULT.INELIGIBLE)
      return
    }
    configInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (sessionId !== '' && !printerInitiated && isSupported) {
      setPrinterInitiated(true)
      printerInit()
    }
  }, [sessionId, printerInit, printerInitiated, isSupported])

  const isStatusValid = useCallback(
    (status) =>
      isLedm
        ? isLanguageCountryStatusValidLedm(status)
        : isLanguageCountryStatusValidCdm(status),
    [isLedm]
  )

  useEffect(() => {
    if (!nextPageLoaded && printerInitiated && liveUiVersion && status) {
      setNextPageLoaded(true)
      if (liveUiVersion === LIVE_UI_VERSION_2 && isStatusValid(status)) {
        findNextPage().then((route) => {
          route && navigate(`..${route}`)
        })
        return
      }
      closeServiceInstance(RESULT.INELIGIBLE)
    }
  }, [
    findNextPage,
    navigate,
    nextPageLoaded,
    printerInitiated,
    liveUiVersion,
    status,
    closeServiceInstance,
    isStatusValid
  ])

  return <FullscreenProgress />
}

export default PortalEntry
