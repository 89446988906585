const packageJson = require('../../package.json')

const [orgName, projectName] = packageJson.name.substring(1).split(/\/(.+)/)

const projectNaming = {
  orgName,
  projectName,
  namespace: `${orgName}__${projectName}`,
  packageJsonName: packageJson.name
}

exports.orgName = projectNaming.orgName
exports.projectName = projectNaming.projectName
exports.namespace = projectNaming.namespace
exports.packageJsonName = projectNaming.packageJsonName

module.exports = projectNaming
