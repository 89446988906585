import {
  filterCalibrationAlerts,
  filterInsertInkAlerts,
  getCalibrationManifestHref,
  getCalibrationSessionHref,
  getCalibrationStateHref,
  getCountry,
  getLanguage,
  getProductConfigCapHref,
  getProductConfigDynHref,
  getProductStatusDynHref,
  getSupportedCountries,
  getSupportedLanguages
} from '../utils/ledm/helpers'
import {
  generateError,
  getCountryByDisplayName,
  getLanguageCode,
  getDisplayName,
  isDHPError
} from '../utils/helpers'
import { useContext } from 'react'
import { ErrorContext } from '../store/ErrorContext'
import { ConfigContext } from '../store/ConfigContext'
import {
  countryDisplayName,
  languageDisplayName,
  SERVICE_ID,
  STATUS_GENERIC_FAILURE,
  START_CALIBRATION_PAYLOAD,
  SET_LOCALE_PAYLOAD
} from '../config/constants'
import { LoggingContext } from '../store/LoggingContext'
const { XMLParser } = require('fast-xml-parser')

const useLedm = (service_id = SERVICE_ID) => {
  const { setError } = useContext(ErrorContext)
  const {
    sessionId,
    sendDHPRequest: plugin,
    isPreview
  } = useContext(ConfigContext)
  const parser = new XMLParser({
    allowBooleanAttributes: true,
    ignoreDeclaration: true,
    processEntities: false
  })
  const { Logger } = useContext(LoggingContext)
  const _handleRequest = async (payload, handleError, useCache = false) => {
    Logger.log(
      `Fetching DHP LEDM - request payload ${JSON.stringify(
        payload
      )} handleError= ${handleError}`
    )
    const response = isPreview ? {} : await plugin(payload, useCache)

    const payloadData = payload?.body?.data ? payload.body.data : null
    const payloadPath = payload.path

    if (!isPreview && handleError && isDHPError(response)) {
      setError(
        generateError(
          { ...response, payload: payloadData, resourceUri: payloadPath },
          service_id
        )
      )
      return null
    }

    if (response?.body?.data) {
      const data = response.body.isBase64Encoded
        ? atob(response.body.data)
        : response.body.data
      try {
        response.body.data = parser.parse(data, true)
      } catch (e) {
        if (handleError) {
          setError(
            generateError(
              {
                errorType: 'unableToDecode',
                reason: e,
                payload: payloadData,
                resourceUri: payloadPath
              },
              service_id
            )
          )
          return null
        }
      }
    }

    Logger.log(`Fetched DHP LEDM - response ${JSON.stringify(response)}`)
    return response
  }

  const fetch = async (href, handleError = true, useCache = false) => {
    const options = {
      path: href,
      sessionId: sessionId,
      method: 'GET',
      calledBy: SERVICE_ID
    }
    return _handleRequest(options, handleError, useCache)
  }

  const _write = (href, payload, handleError = true, method = 'PUT') => {
    const options = {
      path: href,
      sessionId: sessionId,
      method: method,
      body: { data: payload },
      headers: {
        'Content-Type': 'text/xml'
      },
      allowUserInteraction: true,
      calledBy: SERVICE_ID
    }
    return _handleRequest(options, handleError)
  }

  const put = async (href, payload, handleError = true) => {
    return _write(href, payload, handleError)
  }
  const post = async (href, payload, handleError = true) => {
    return _write(href, payload, handleError, 'POST')
  }

  const fetchDiscoveryTree = async () => {
    return fetch('/DevMgmt/DiscoveryTree.xml', true, true)
  }

  const getLocale = async (discoveryTree) => {
    const result = {
      language: null,
      country: null,
      supportedLanguages: [],
      supportedCountries: [],
      supportedLanguagesDisplayName: [],
      supportedCountriesDisplayName: []
    }

    let response = await fetch(
      getProductConfigDynHref(discoveryTree),
      true,
      true
    )
    result.language = getLanguage(response?.body?.data)
    result.country = getCountry(response?.body?.data)
    response = await fetch(getProductConfigCapHref(discoveryTree), true, true)
    result.supportedLanguages = getSupportedLanguages(response?.body?.data)
    result.supportedLanguagesDisplayName = getDisplayName(
      result.supportedLanguages,
      languageDisplayName
    )
    result.supportedCountries = getSupportedCountries(response?.body?.data)
    result.supportedCountriesDisplayName = getDisplayName(
      result.supportedCountries,
      countryDisplayName
    )

    return result
  }

  const patchLocale = async (
    discoveryTree,
    language,
    country,
    handleError = true
  ) => {
    const payload = SET_LOCALE_PAYLOAD.replace(
      '$language',
      getLanguageCode(language)
    ).replace('$country', getCountryByDisplayName(country))
    return await put(
      getProductConfigDynHref(discoveryTree),
      payload,
      handleError
    )
  }

  const startCalibration = async (discoveryTree) => {
    const { state, href } = await _fetchCalibrationState(
      getCalibrationManifestHref(discoveryTree)
    )

    if (!state) return { errorType: STATUS_GENERIC_FAILURE }

    if (['CalibrationRequired', 'CalibrationValid'].indexOf(state) !== -1) {
      return await post(href, START_CALIBRATION_PAYLOAD, false)
    }
    return { statusCode: 200 }
  }

  const _fetchCalibrationState = async (manifestHref) => {
    const result = {
      state: null,
      href: null
    }
    let resp = await fetch(manifestHref, false, true)
    const href = getCalibrationStateHref(resp?.body?.data)
    result.href = getCalibrationSessionHref(resp?.body?.data)
    resp = await fetch(href, false, false)
    result.state = resp?.body?.data?.['CalibrationState']
    return result
  }

  const _fetchAlerts = async (discoveryTree) => {
    return await fetch(getProductStatusDynHref(discoveryTree))
  }

  const fetchInkAlerts = async (discoveryTree) => {
    const response = await _fetchAlerts(discoveryTree)
    return filterInsertInkAlerts(
      response?.body?.data?.['psdyn:ProductStatusDyn']?.['psdyn:AlertTable']?.[
        'psdyn:Alert'
      ]
    )
  }

  const fetchCalibrationAlerts = async (discoveryTree) => {
    const response = await _fetchAlerts(discoveryTree)
    return filterCalibrationAlerts(
      response?.body?.data?.['psdyn:ProductStatusDyn']?.['psdyn:AlertTable']?.[
        'psdyn:Alert'
      ]
    )
  }

  return {
    getLocale,
    patchLocale,
    fetch,
    fetchDiscoveryTree,
    startCalibration,
    put,
    post,
    fetchInkAlerts,
    fetchCalibrationAlerts
  }
}

export default useLedm
