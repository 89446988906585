import React from 'react'
import { ShellRootComponentProps } from '../src/types/shell'
import Template from './root.component'
import { AppWrapper } from '../language-country'

export default function Root({
  useToast,
  properties,
  onMount,
  onUnmount,
  ...props
}: ShellRootComponentProps) {
  const appComponent = <AppWrapper {...{ ...props, properties }} />
  return (
    <Template {...{ useToast, properties, onMount, onUnmount, appComponent }} />
  )
}
