// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BAW3SB39lASXeUUK3WCf{height:100%}.BAW3SB39lASXeUUK3WCf .yHPD6Gf3oETLvfhvKoXX{align-items:center;margin:auto;padding:0 0 60px}.BAW3SB39lASXeUUK3WCf .UZi_dyzERIik_aKlBYnS{display:flex;flex-direction:column;align-items:center;justify-content:center;height:inherit;gap:15px}.BAW3SB39lASXeUUK3WCf .AqKsn4bRS9leoVsRzPln{position:fixed;top:50%;left:50%;transform:translate(-50%, -50%);display:flex;flex-direction:column;align-items:center;gap:15px}.BAW3SB39lASXeUUK3WCf div[role=progressbar]{width:50px;height:50px}.BAW3SB39lASXeUUK3WCf .vQDzlIsk2bXrGDcJWWLT{display:block;text-align:center}", "",{"version":3,"sources":["webpack://./src/pages/Completed.module.scss"],"names":[],"mappings":"AAEA,sBACC,WAAA,CAEA,4CACC,kBAAA,CACA,WAAA,CACA,gBAAA,CAGD,4CACC,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA,CACA,QAAA,CAGD,4CACC,cAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,QAAA,CAGD,4CACC,UAAA,CACA,WAAA,CAGD,4CACC,aAAA,CACA,iBAAA","sourcesContent":["@import '../assets/variables';\n\n.completed {\n\theight: 100%;\n\n\t.spinner {\n\t\talign-items: center;\n\t\tmargin: auto;\n\t\tpadding: 0 0 60px;\n\t}\n\n\t.progressBarHpx {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\talign-items: center;\n\t\tjustify-content: center;\n\t\theight: inherit;\n\t\tgap: 15px;\n\t}\n\n\t.progressBar {\n\t\tposition: fixed;\n\t\ttop: 50%;\n\t\tleft: 50%;\n\t\ttransform: translate(-50%, -50%);\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\talign-items: center;\n\t\tgap: 15px;\n\t}\n\n\tdiv[role='progressbar'] {\n\t\twidth: 50px;\n\t\theight: 50px;\n\t}\n\n\t.label {\n\t\tdisplay: block;\n\t\ttext-align: center;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"completed": "BAW3SB39lASXeUUK3WCf",
	"spinner": "yHPD6Gf3oETLvfhvKoXX",
	"progressBarHpx": "UZi_dyzERIik_aKlBYnS",
	"progressBar": "AqKsn4bRS9leoVsRzPln",
	"label": "vQDzlIsk2bXrGDcJWWLT"
};
export default ___CSS_LOADER_EXPORT___;
