import {
  COUNTRY_CONFIG,
  LANGUAGE_CONFIG,
  STATUS_COMPLETED
} from '../../../src/config/constants'

const _getOOBEStageState = (oobeStatus, stageName) => {
  return (
    oobeStatus?.['ob:OOBEStatus']?.['ob:OOBEStage']?.find(
      (stage) => stage['ob:Name'] === stageName
    )?.['ob:State'] || null
  )
}

export const isLanguageCountrySupportedAndNotCompleted = (oobeStatus) => {
  const getStatus = (status) => _getOOBEStageState(oobeStatus, status)

  const languageStatus = getStatus(LANGUAGE_CONFIG)
  const countryStatus = getStatus(COUNTRY_CONFIG)
  return (
    languageStatus !== null &&
    countryStatus !== null &&
    (languageStatus !== STATUS_COMPLETED || countryStatus !== STATUS_COMPLETED)
  )
}
