import {
  STATUS_PENDING,
  STATUS_COMPLETED,
  SUPPORTED_CDM_ACTIONS,
  countryIsoDisplayName,
  languageDisplayName,
  PAPER_TRAY_PACKAGING,
  CARRIAGE_RESTRAINT,
  CARTRIDGE_COUNTERFEIT_ERROR,
  SINGLE_CARTRIDGE_MODE_ERROR,
  STATUS_FAILED,
  RESULT,
  STATUS_IN_PROGRESS,
  STATUS_INTERVENTION,
  _KNOW_CALIBRATION_ALERTS,
  _IGNORABLE_CALIBRATION_ALERTS,
  STATUS_NOT_STARTED,
  SUPPLY_NOT_DETECTED_ERROR,
  SUPPLY_ERROR,
  TIME_OUT,
  TIME_OUT_ERROR,
  _KNOW_CARTRIDGE_ALERTS,
  _IGNORABLE_CARTRIDGE_ALERTS,
  RESOURCE_URI_FALLBACK,
  TRANSITION_TYPE_AUTO,
  ACTION_LOAD_MAIN_TRAY,
  ACTION_SEMI_AUTO_CALIBRATION,
  ACTION_FILL_INK_TANKS,
  ACTION_INSTALL_PHA,
  ACTION_INSTALL_CARTRIDGES,
  CARTRIDGE_COLORS
} from '../../config/constants'
import {
  Logger,
  sortOnKeys,
  exportActionStateToList,
  returnAlertByPriority,
  parseAlertByPriority
} from '../helpers'

const { CDM: URI } = RESOURCE_URI_FALLBACK

const getUnsupportedActions = (deviceSetup) => {
  const unsupportedActions = Object.keys(deviceSetup).filter(
    (key) => key.startsWith('action') && !SUPPORTED_CDM_ACTIONS.includes(key)
  )
  return Object.keys(unsupportedActions).map((key) => {
    return { name: unsupportedActions[key], result: RESULT.INELIGIBLE }
  })
}

const getAlreadyPerformedActions = (deviceSetup) => {
  const supportedAndAlreadyPerformedActions = Object.keys(deviceSetup).filter(
    (key) =>
      SUPPORTED_CDM_ACTIONS.includes(key) &&
      deviceSetup[key]?.status === STATUS_COMPLETED
  )
  return Object.keys(supportedAndAlreadyPerformedActions).map((key) => {
    return {
      name: supportedAndAlreadyPerformedActions[key],
      result: RESULT.ALREADY_PERFORMED
    }
  })
}

export const findIneligibleOrAlreadyPerformedActions = (deviceSetup) => {
  return [
    ...getUnsupportedActions(deviceSetup),
    ...getAlreadyPerformedActions(deviceSetup)
  ]
}

export const getNextActionBySuggestedOrder = (
  deviceSetup,
  liveUiVersion,
  actionState
) => {
  const keys = Object.keys(deviceSetup)
  const actionsToSuppress = exportActionStateToList(actionState)
  let suggestedOrder = {}

  if (deviceSetup['softwareCompletionState'] === 'completed') {
    return { name: STATUS_COMPLETED }
  }

  for (const key of keys) {
    if (
      SUPPORTED_CDM_ACTIONS.includes(key) && // process only if a supported action
      !actionsToSuppress.includes(key) &&
      deviceSetup[key].status === STATUS_PENDING &&
      deviceSetup[key].userResponse !== STATUS_COMPLETED &&
      !_canBeSkipped(key, deviceSetup, actionState)
    ) {
      suggestedOrder[deviceSetup[key].suggestedOrder] = key
    }
  }
  if (!Object.keys(suggestedOrder).length) {
    return { name: STATUS_COMPLETED }
  }
  suggestedOrder = sortOnKeys(suggestedOrder)
  const action = suggestedOrder[Object.keys(suggestedOrder)[0]]
  return {
    ...deviceSetup[action],
    name: action.charAt(6).toLowerCase() + action.slice(7)
  }
}

const _canBeSkipped = (action, deviceSetup, actionState) => {
  switch (action) {
    case ACTION_LOAD_MAIN_TRAY:
      return _canLoadMainTrayBeSkipped(deviceSetup)
    case ACTION_SEMI_AUTO_CALIBRATION:
      return _canCalibrationBeSkipped(deviceSetup, actionState)
    default:
      return false
  }
}

const _canLoadMainTrayBeSkipped = (deviceSetup) => {
  return (
    deviceSetup.actionLoadMainTray?.transitionType === TRANSITION_TYPE_AUTO &&
    !!deviceSetup.actionSemiAutoCalibration &&
    deviceSetup.actionSemiAutoCalibration?.status !== STATUS_PENDING
  )
}

const _canCalibrationBeSkipped = (deviceSetup, actionState) => {
  if (
    deviceSetup.actionSemiAutoCalibration?.transitionType ===
    TRANSITION_TYPE_AUTO
  ) {
    const skippedStages = Object.values(actionState).filter(
      (item) =>
        item.result === RESULT.DECLINED || item.result === RESULT.FAILURE
    )
    const stages = Object.values(skippedStages).map((item) => item.name)
    return (
      stages.includes(ACTION_INSTALL_CARTRIDGES) ||
      stages.includes(ACTION_FILL_INK_TANKS) ||
      stages.includes(ACTION_INSTALL_PHA) ||
      stages.includes(ACTION_LOAD_MAIN_TRAY)
    )
  }
  return false
}

const getHref = (action, rel) => {
  try {
    return action['links'].find((link) => link['rel'] === rel).href
  } catch (e) {
    let log = `Not able to find action href for rel ${rel} - ${e}`
    if (action) log += ` - ${JSON.stringify(action)}`
    Logger.warn(log)
    return null
  }
}

const getService = (servicesDiscovery, resourceGun) => {
  try {
    return servicesDiscovery['services'].find(
      (service) => service['serviceGun'] === resourceGun
    )
  } catch (e) {
    let log = `Not able to find service ${resourceGun} - ${e}`
    if (servicesDiscovery) log += ` - ${JSON.stringify(servicesDiscovery)}`
    Logger.warn(log)
    return null
  }
}

export const getDeviceSetupHref = (servicesDiscovery) => {
  return (
    getHref(
      getService(servicesDiscovery, 'com.hp.cdm.service.deviceSetup.version.1'),
      'status'
    ) || URI.deviceSetup
  )
}

export const getSystemIdentityHref = (servicesDiscovery) => {
  return (
    getHref(
      getService(servicesDiscovery, 'com.hp.cdm.service.system.version.1'),
      'identity'
    ) || URI.systemIdentity
  )
}

export const getSystemConfigHref = (languageCountryAction) => {
  return getHref(languageCountryAction, 'configuration') || URI.systemConfig
}

export const getSystemConfigConstraintsHref = (systemConfig) => {
  return getHref(systemConfig, 'constraints') || URI.systemConstraints
}

export const getReportsPrintHref = (actionSemiAutoCalibration) => {
  return getHref(actionSemiAutoCalibration, 'print') || URI.reportsPrint
}

export const getCalibrationCapabilitiesHref = (actionSemiAutoCalibration) => {
  return (
    getHref(actionSemiAutoCalibration, 'penAlignSemiauto') ||
    URI.calibrationCapabilities
  )
}

export const getCalibrationAlertsHref = (reportsPrint) => {
  return getHref(reportsPrint, 'intervention') || URI.calibrationAlerts
}

export const getSuppliesPublicHref = (actionSuppliesPublic) => {
  return getHref(actionSuppliesPublic, 'suppliesPublic') || URI.suppliesPublic
}

export const getPackageInformationHref = (actionPackageInformation) => {
  return (
    getHref(actionPackageInformation, 'packageInformation') ||
    URI.packageInformation
  )
}

export const getCartridgeSetupHref = (actionInstallCartridges) => {
  return (
    getHref(actionInstallCartridges, 'cartridgeSetup') || URI.cartridgeSetup
  )
}

export const getCartridgeAlertsHref = (cartridgeSetup) => {
  return getHref(cartridgeSetup, 'alerts') || URI.cartridgeAlerts
}

export const getLoadMainTrayActionStatus = (deviceSetup) => {
  if (deviceSetup?.actionLoadMainTray?.status === STATUS_COMPLETED) {
    return STATUS_COMPLETED
  }
  return STATUS_PENDING
}

const isSupplyPublicSuccess = (supplyPublic, type) => {
  try {
    return !supplyPublic['suppliesList'].find(
      (currentValue) =>
        currentValue['supplyType'] === type &&
        currentValue['supplyState'] !== 'ok'
    )
  } catch {
    return false
  }
}

const isInstallPhaError = (suppliesPublic) => {
  try {
    return !!suppliesPublic['suppliesList'].find(
      (currentValue) =>
        currentValue['supplyType'] === 'inkCartridge' &&
        currentValue['supplyState'] === 'error' &&
        currentValue['stateReasons'].includes('cartridgeFailureError')
    )
  } catch {
    return false
  }
}

export const getInstallPhaActionStatus = (suppliesPublic) => {
  let status = STATUS_PENDING
  if (isSupplyPublicSuccess(suppliesPublic, 'inkCartridge'))
    status = STATUS_COMPLETED
  else if (isInstallPhaError(suppliesPublic)) status = STATUS_FAILED
  return status
}

const isFillInkTanksError = (suppliesPublic) => {
  try {
    const inkTanks = suppliesPublic['suppliesList'].filter(
      (item) => item['supplyType'] === 'inkTank'
    )
    const inkTankErrors = inkTanks.filter(
      (item) => item['supplyState'] === 'error'
    )

    if (inkTankErrors.length === 0) {
      return false
    }
    // Error condition 1: 1 to n-1 inkTanks show supplyState error
    if (inkTankErrors.length < inkTanks.length) {
      return true
    }
    // Error condition 2: all inkTanks show supplyState error and at least one stateReasons does not contain 'outOfInkError'
    return !inkTankErrors.every((item) =>
      item['stateReasons'].includes('outOfInkError')
    )
  } catch {
    return false
  }
}

export const getFillInkTanksActionStatus = (suppliesPublic) => {
  let status = STATUS_PENDING
  if (isSupplyPublicSuccess(suppliesPublic, 'inkTank'))
    status = STATUS_COMPLETED
  else if (isFillInkTanksError(suppliesPublic)) status = STATUS_FAILED
  return status
}

export const getLanguage = (systemConfig) => {
  return getLanguageByCode(systemConfig['deviceLanguage'])
}

export const getCountry = (systemConfig) => {
  return getCountryByCode(systemConfig['countryRegion'])
}

const getLanguageByCode = (language) => {
  return languageDisplayName[language]
}

const getCountryByCode = (country) => {
  return countryIsoDisplayName[country]
}

export const getSupportedLanguages = (systemConstraints) => {
  return mapSystemConstraints(systemConstraints, '/deviceLanguage')
}

export const getSupportedCountries = (systemConstraints) => {
  return mapSystemConstraints(systemConstraints, '/countryRegion')
}

const mapSystemConstraints = (systemConstraints, propertyPointer) => {
  const constraints =
    systemConstraints['validators']?.find(
      (validator) => validator['propertyPointer'] === propertyPointer
    )?.options || {}
  return Object.values(constraints).map((element) => element['seValue'])
}

export const getPackageInformationType = (data, type) => {
  switch (type) {
    case PAPER_TRAY_PACKAGING:
      return data?.paperTrayPackaging === 'true'
    case CARRIAGE_RESTRAINT:
      return data?.carriageRestraint === 'true'
    default:
      return false
  }
}

const isPhotoTrayAlert = (alert) => {
  return (
    (alert?.data || []).filter(
      (item) =>
        item.propertyPointer?.includes('mediaSourceId') &&
        item.value?.seValue?.toLowerCase() === 'photo'
    ).length > 0
  )
}

export const filterAlertsBySeverityAndPriority = (
  alerts,
  known_alerts,
  ignorable_alerts,
  is_ink_alert
) => {
  let highestPriorityAlert = []
  let highestPriorityError = []
  let cartridges = []

  const filterCartridge = (alertData) => {
    const alerts = alertData || []
    const filteredCartridge = alerts
      .filter((item) => {
        const seValue = item?.value?.seValue
        return (
          seValue &&
          [
            CARTRIDGE_COLORS.CDM.CYAN,
            CARTRIDGE_COLORS.CDM.CYANMAGENTAYELLOW,
            CARTRIDGE_COLORS.CDM.BLACK,
            CARTRIDGE_COLORS.CDM.MAGENTA,
            CARTRIDGE_COLORS.CDM.YELLOW
          ].includes(seValue)
        )
      })
      .map((item) => item.value.seValue)

    return filteredCartridge.length > 0 ? filteredCartridge[0] : null
  }

  alerts?.forEach((alertElement) => {
    const alert = {
      StringId: alertElement.stringId,
      Severity: alertElement.severity,
      Priority: alertElement.priority,
      Category: alertElement.category,
      Cartridge: filterCartridge(alertElement.data)
    }
    if (isPhotoTrayAlert(alertElement)) {
      return
    }
    parseAlertByPriority(
      alert,
      ignorable_alerts,
      is_ink_alert,
      highestPriorityAlert,
      known_alerts,
      cartridges,
      highestPriorityError
    )
  })

  return returnAlertByPriority(
    highestPriorityAlert,
    is_ink_alert,
    known_alerts,
    highestPriorityError,
    cartridges
  )
}

export const isBlockingAlert = (alert) => {
  switch (alert) {
    case 'cartridgeLow':
    case 'cartridgeLowHP+':
    case 'cartridgeLowTanks':
    case 'cartridgeVeryLow':
    case 'cartridgeVeryLowHP+':
    case 'cartridgeVeryLowTanks':
    case 'subscriptionConsumableNeedsEnrollment':
    case CARTRIDGE_COUNTERFEIT_ERROR:
    case SINGLE_CARTRIDGE_MODE_ERROR:
    case 'singleCartridgeModeEnabled':
    case 'trialConsumableCountExceeded':
    case 'printerNeedsWSRegistration':
    case 'subscriptionCancellationSuccessful':
    case 'subscriptionCancellationSuccessfulXMO':
    case 'paperSizeMismatchWarning':
      return false
    default:
      return true
  }
}

export const getPrintState = (reportsPrint) => {
  if (!reportsPrint) return STATUS_PENDING
  if (
    reportsPrint['state'] === 'idle' &&
    reportsPrint['lastResult'] === 'success'
  ) {
    return STATUS_COMPLETED
  }
  if (reportsPrint['state'] === 'processing') {
    return STATUS_IN_PROGRESS
  }
  if (
    reportsPrint['state'] === 'idle' &&
    reportsPrint['lastResult'] === 'failure'
  ) {
    return STATUS_FAILED
  }
  if (reportsPrint['state'] === 'intervention') {
    return STATUS_INTERVENTION
  }
  return STATUS_PENDING
}

export const getPrintAlignmentErrorMessage = (errorName) => {
  if (errorName === 'systemCancelled') {
    return 'common.printAlignment.error.systemCancelled'
  }
  if (errorName === 'userCancelled') {
    return 'common.printAlignment.error.userCancelled'
  }
  return 'common.errors.alignmentReprintOrSkipError'
}

export const filterCalibrationAlerts = (alerts) => {
  return filterAlertsBySeverityAndPriority(
    alerts,
    _KNOW_CALIBRATION_ALERTS,
    _IGNORABLE_CALIBRATION_ALERTS,
    false
  )
}

export const getScanState = (calibrationCapabilities) => {
  if (!calibrationCapabilities) return STATUS_NOT_STARTED
  if (
    calibrationCapabilities['calibrationStatus'] === 'complete' &&
    calibrationCapabilities['lastResult'] === 'success' &&
    calibrationCapabilities['state'] === 'idle'
  ) {
    return STATUS_COMPLETED
  }
  if (
    calibrationCapabilities['calibrationStatus'] === 'notComplete' &&
    calibrationCapabilities['state'] === 'processing'
  ) {
    return STATUS_IN_PROGRESS
  }
  if (
    calibrationCapabilities['calibrationStatus'] === 'notComplete' &&
    calibrationCapabilities['lastResult'] === 'failed' &&
    calibrationCapabilities['failureReason'] === 'calibrationError'
  ) {
    return STATUS_FAILED
  }
  if (
    calibrationCapabilities['calibrationStatus'] === 'notComplete' &&
    calibrationCapabilities['state'] === 'idle'
  ) {
    return STATUS_NOT_STARTED
  }
}

export const getCartridgeState = (cartridgeSetup) => {
  if (isCartridgeSuccess(cartridgeSetup)) return STATUS_COMPLETED
  if (isSupplyNotDetectedError(cartridgeSetup)) return SUPPLY_NOT_DETECTED_ERROR
  if (isSupplyError(cartridgeSetup)) return SUPPLY_ERROR
  if (isError(TIME_OUT, cartridgeSetup)) return TIME_OUT_ERROR
}

// Success condition: 'state' should be 'completed' or 'lastResult' be 'success'
const isCartridgeSuccess = (cartridgeSetup) => {
  return (
    cartridgeSetup &&
    cartridgeSetup['state'] &&
    (cartridgeSetup['state'] === 'completed' ||
      (cartridgeSetup['state'] === 'idle' &&
        cartridgeSetup['lastResult'] &&
        cartridgeSetup['lastResult'] === 'success'))
  )
}

export const isError = (type, cartridgeSetup) => {
  return (
    cartridgeSetup &&
    cartridgeSetup['state'] &&
    cartridgeSetup['state'] === 'idle' &&
    cartridgeSetup['failureReason'] &&
    cartridgeSetup['failureReason'] === type
  )
}

// Error condition: 'state' is 'idle' and 'failureReason' is 'supplyNotDetected'
const isSupplyNotDetectedError = (cartridgeSetup) => {
  return isError('supplyNotDetected', cartridgeSetup)
}

// Error condition: 'state' is 'idle' and 'failureReason' is 'supplyError'
export const isSupplyError = (cartridgeSetup) => {
  return isError('supplyError', cartridgeSetup) && cartridgeSetup['links']
}

export const filterCartridgeAlerts = (alerts) => {
  return filterAlertsBySeverityAndPriority(
    alerts,
    _KNOW_CARTRIDGE_ALERTS,
    _IGNORABLE_CARTRIDGE_ALERTS,
    true
  )
}
