import React, { useState, useMemo } from 'react'
import { EMPTY_FUNCTION } from '../config/constants'

export const ErrorContext = React.createContext({
  setError: EMPTY_FUNCTION,
  error: null
})

const ErrorProvider = (props) => {
  const [error, setError] = useState(null)

  const errorState = useMemo(
    () => ({
      error,
      setError
    }),
    [error]
  )

  return (
    <ErrorContext.Provider value={errorState}>
      {props.children}
    </ErrorContext.Provider>
  )
}

export default ErrorProvider
