import React from 'react'
import { EMPTY_FUNCTION } from '../config/constants'

export const LoggingContext = React.createContext({
  Logger: EMPTY_FUNCTION
})

const LoggingProvider = (props) => {
  const { logger } = props

  const logging = React.useMemo(
    () => ({
      Logger: logger
    }),
    [logger]
  )

  return (
    <LoggingContext.Provider value={logging}>
      {props.children}
    </LoggingContext.Provider>
  )
}

export default LoggingProvider
