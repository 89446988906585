import { useCallback, useRef, useContext } from 'react'
import { WhenJWebReady } from '@jarvis/jweb-core'
import {
  CACHE_STRATEGY_CACHEFIRST,
  DHP_ERROR_CODE,
  DHP_REQUEST_REJECTED_ERROR,
  MAX_AGE_IN_SECONDS
} from '../config/constants'
import { LoggingContext } from '../store/LoggingContext'

const useDeviceHttpProxy = () => {
  const { Logger } = useContext(LoggingContext)
  const isMockProxy = useRef(false)
  const browser = useRef(window.self)
  const popup = useRef(null)
  const timer = useRef(null)
  const deviceHttpProxy = useRef(null)
  const launchDHPEmulator = (url, selectedDeviceSetup, selectedDeviceModel) => {
    browser.current['selectedDeviceSetup'] = selectedDeviceSetup
    browser.current['selectedDeviceModel'] = selectedDeviceModel

    if (popup.current && !popup.current.closed) {
      return
    }
    const opts = `dependent=${1}, alwaysOnTop=${1}, alwaysRaised=${1}, 'scrollbars=yes, resizable=yes, width=${1024}, height=${728}`
    popup.current = browser.current.open(url, 'DeviceHttpProxy Emulator', opts)
    isMockProxy.current = true

    if (!timer.current) {
      timer.current = setInterval(watcher, 2000)
    }
  }

  const watcher = () => {
    if (popup.current === null || popup.current.closed) {
      clearInterval(timer.current)
      browser.current.focus()
      timer.current = null
    }
  }

  const sendDHPRequest = useCallback(
    async (options, useCache = false) => {
      if (isMockProxy.current) {
        Logger.log('DHP is mocked')
        return browser.current['sendRequest'](options)
      }
      try {
        if (useCache && deviceHttpProxy.current.sendRequestWithCacheStrategy) {
          Logger.log('Calling DHP plugin sendRequestWithCacheStrategy')
          return await deviceHttpProxy.current.sendRequestWithCacheStrategy({
            request: options,
            cacheStrategy: CACHE_STRATEGY_CACHEFIRST,
            maxAgeInSeconds: MAX_AGE_IN_SECONDS
          })
        }
        Logger.log('Calling DHP plugin')
        return await deviceHttpProxy.current.sendRequest(options)
      } catch (e) {
        const errorType = DHP_ERROR_CODE[e] ? e : DHP_REQUEST_REJECTED_ERROR
        const reason = `Call to DHP plugin has failed - plugin has not been initialized and/or is not available - ${e}`
        Logger.warn(reason)
        return { errorType, reason }
      }
    },
    [isMockProxy, deviceHttpProxy, Logger]
  )

  const initializeDHP = async () => {
    let response = await WhenJWebReady
    response = typeof response !== 'function' ? response : await response()

    deviceHttpProxy.current = response?.Plugins?.DeviceHttpProxy || null
    if (!deviceHttpProxy.current) {
      Logger.error('JWeb plugin: DeviceHttpProxy not available')
    }
  }

  return {
    deviceHttpProxy,
    initializeDHP,
    launchDHPEmulator,
    sendDHPRequest
  }
}

export default useDeviceHttpProxy
