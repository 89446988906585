import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react'
import { PrinterContext } from './PrinterContext'
import {
  TRANSITION_TYPE_MANUAL,
  TRANSITION_TYPE_AUTO,
  EMPTY_FUNCTION
} from '../config/constants'
import {
  findIneligibleOrAlreadyPerformedActions,
  getNextActionBySuggestedOrder
} from '../utils/cdm/helpers'
import {
  findIneligibleOrAlreadyPerformedActions as findIneligibleOrAlreadyPerformedActionsLedm,
  getNextActionBySuggestedOrder as getNextActionBySuggestedOrderForLedm
} from '../utils/ledm/helpers'
import usePrinter from '../hooks/usePrinter'

export const ActionContext = React.createContext({
  currentAction: {},
  isCurrentActionAuto: false,
  findNextPage: EMPTY_FUNCTION,
  state: []
})

const ActionProvider = (props) => {
  const { isLedm, discoveryTree, oobeManifestTree, liveUiVersion } =
    useContext(PrinterContext)
  const { fetchStatus } = usePrinter(isLedm, discoveryTree, oobeManifestTree)
  const actionState = useRef([])
  const actionStateInitialized = useRef(false)
  const [currentAction, setCurrentAction] = useState({})
  const getNextAction = isLedm
    ? getNextActionBySuggestedOrderForLedm
    : getNextActionBySuggestedOrder
  const findIneligibleOrAlreadyPerformedAction = isLedm
    ? findIneligibleOrAlreadyPerformedActionsLedm
    : findIneligibleOrAlreadyPerformedActions

  const findNextPage = useCallback(
    async (actions = [], result = null) => {
      const resp = await fetchStatus()
      if (!resp) return
      if (!actionStateInitialized.current) {
        actionStateInitialized.current = true
        actionState.current = findIneligibleOrAlreadyPerformedAction(resp)
      }

      for (const action of actions) {
        if (action && result) {
          actionState.current.push({ name: action, result: result })
        }
      }

      const nextAction = getNextAction(resp, liveUiVersion, actionState.current)
      setCurrentAction(nextAction)
      return `/${nextAction.name}`
    },
    [
      fetchStatus,
      findIneligibleOrAlreadyPerformedAction,
      getNextAction,
      liveUiVersion
    ]
  )

  const _isCurrentActionAuto = useCallback(() => {
    /*
     * For LEDM devices - OOBE Status tree might not have ob:StateTransitionType
     * property. In such cases, we need to assume that the transition type is auto.
     */
    return isLedm
      ? currentAction?.['ob:StateTransitionType'] !== TRANSITION_TYPE_MANUAL
      : currentAction?.transitionType === TRANSITION_TYPE_AUTO
  }, [currentAction, isLedm])

  const state = useMemo(
    () => ({
      currentAction,
      isCurrentActionAuto: _isCurrentActionAuto(),
      findNextPage,
      state: actionState.current
    }),
    [currentAction, _isCurrentActionAuto, findNextPage]
  )

  return (
    <ActionContext.Provider value={state}>
      {props.children}
    </ActionContext.Provider>
  )
}

export default ActionProvider
