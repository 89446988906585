import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { ConfigContext } from './ConfigContext'
import { LoggingContext } from './LoggingContext'
import { ErrorContext } from './ErrorContext'
import { generateError } from '../utils/helpers'
import {
  getParsedProductName,
  getProductFamily
} from '../utils/modelNameParser'
import {
  EMPTY_FUNCTION,
  DEVICE_NOT_SUPPORTED,
  DEVICE_CDM_ONLY,
  DEVICE_LEDM_ONLY,
  DHP_OOBE_MANIFEST_TREE_EMPTY_ERROR,
  DHP_DISCOVERY_TREE_EMPTY_ERROR
} from '../config/constants'
import usePrinter from '../hooks/usePrinter'

export const PrinterContext = React.createContext({
  discoveryTree: null,
  oobeManifestTree: null,
  productFamily: null,
  modelName: null,
  factoryModelName: null,
  liveUiVersion: null,
  init: EMPTY_FUNCTION,
  isSupported: null,
  isLedm: null,
  productNumber: null
})

const PrinterProvider = (props) => {
  const { sessionContext } = useContext(ConfigContext)
  const { setError, error } = useContext(ErrorContext)
  const [productFamily, setProductFamily] = useState('')
  const [modelName, setModelName] = useState('')
  const [productNumber, setProductNumber] = useState('')
  const [factoryModelName, setFactoryModelName] = useState('')
  const [discoveryTree, setDiscoveryTree] = useState(null)
  const [oobeManifestTree, setOobeManifestTree] = useState(null)
  const [fetchedManifest, setFetchedManifest] = useState(false)
  const [fetchedDeviceInfo, setFetchedDeviceInfo] = useState(false)
  const [liveUiVersion, setLiveUiVersion] = useState(null)
  const [fetchedLiveUiVersion, setFetchedLiveUiVersion] = useState(false)
  const [resourceError, setResourceError] = useState(null)
  const { Logger } = useContext(LoggingContext)

  const liveUiCapability =
    sessionContext?.device?.liveUiCapability || DEVICE_NOT_SUPPORTED
  const isSupported =
    liveUiCapability === DEVICE_CDM_ONLY ||
    liveUiCapability === DEVICE_LEDM_ONLY
  const isLedm = liveUiCapability === DEVICE_LEDM_ONLY

  const {
    fetchDiscovery,
    fetchOobeManifest,
    fetchLiveUiVersion,
    fetchDeviceInfo
  } = usePrinter(isLedm, discoveryTree, oobeManifestTree)

  /* Hook responsible for setting generic error in case unable to pull required
   * resources */
  useEffect(() => {
    if (resourceError && !error) {
      setError(generateError({ errorType: resourceError }))
    }
  }, [resourceError, error, setError])

  /* Async hook callback for fetching OOBE manifest tree */
  const fetchManifest = useCallback(async () => {
    const tree = await fetchOobeManifest()
    if (!tree) {
      Logger.warn('Unable to fetch OOBE manifest tree')
      setResourceError(DHP_OOBE_MANIFEST_TREE_EMPTY_ERROR)
      return
    }
    setOobeManifestTree(JSON.stringify(tree))
  }, [fetchOobeManifest, Logger])

  /* Hook responsible for fetching OOBE manifest tree for LEDM only once
    discovery tree and fetched attributes are available */
  useEffect(() => {
    if (
      !fetchedManifest &&
      !oobeManifestTree &&
      discoveryTree &&
      modelName &&
      isLedm
    ) {
      setFetchedManifest(true)
      fetchManifest()
    }
  }, [
    fetchedManifest,
    discoveryTree,
    oobeManifestTree,
    isLedm,
    fetchManifest,
    modelName,
    Logger
  ])

  /* Async hook callback for fetching device info like model name and product number */

  const _fetchDeviceInfo = useCallback(async () => {
    let _productNumber = sessionContext?.device?.productNumber
    let _modelName = sessionContext?.device?.modelName

    if (!_productNumber || !_modelName) {
      const deviceInfo = await fetchDeviceInfo()
      _productNumber = deviceInfo?.productNumber
      _modelName = deviceInfo?.modelName
    }

    const nearestModelName = getParsedProductName(_modelName)
    const _productFamily = getProductFamily(nearestModelName)

    Logger.log(`model name - ${nearestModelName}`)
    Logger.log(`product family - ${_productFamily}`)
    Logger.log(`product number - ${_productNumber}`)
    setFactoryModelName(_modelName)
    setProductNumber(_productNumber)
    setProductFamily(_productFamily)
    setModelName(nearestModelName)
  }, [
    sessionContext?.device?.productNumber,
    sessionContext?.device?.modelName,
    fetchDeviceInfo,
    Logger
  ])

  /* Hook responsible for fetching model name once discovery tree is available */
  useEffect(() => {
    if (discoveryTree && !fetchedDeviceInfo && !modelName) {
      setFetchedDeviceInfo(true)
      _fetchDeviceInfo().then()
    }
  }, [discoveryTree, fetchedDeviceInfo, modelName, _fetchDeviceInfo])

  /* Async hook callback for fetching live ui version */
  const checkLiveUiVersion = useCallback(async () => {
    let _liveUiVersion = await fetchLiveUiVersion()
    Logger.log(`LiveUI version - ${_liveUiVersion}`)
    setLiveUiVersion(_liveUiVersion)
  }, [fetchLiveUiVersion, Logger])

  /* Hook responsible for fetching LiveUIVersion once OOBE manifest tree is
   * available for LEDM */
  useEffect(() => {
    if (
      !fetchedLiveUiVersion &&
      !liveUiVersion &&
      ((!isLedm && discoveryTree) || oobeManifestTree)
    ) {
      setFetchedLiveUiVersion(true)
      checkLiveUiVersion()
    }
  }, [
    modelName,
    discoveryTree,
    oobeManifestTree,
    fetchedLiveUiVersion,
    liveUiVersion,
    checkLiveUiVersion,
    isLedm,
    Logger
  ])

  /* Init method is responsible for fetching discovery tree. This will trigger
   * other dependent hooks */
  const init = useCallback(async () => {
    const _discoveryTree = await fetchDiscovery()
    if (!_discoveryTree) {
      Logger.warn('Unable to fetch discovery tree')
      setResourceError(DHP_DISCOVERY_TREE_EMPTY_ERROR)
      return
    }
    setDiscoveryTree(JSON.stringify(_discoveryTree))
  }, [Logger, fetchDiscovery])

  const printerState = useMemo(
    () => ({
      discoveryTree,
      oobeManifestTree,
      productFamily,
      modelName,
      productNumber,
      factoryModelName,
      liveUiVersion,
      init,
      isSupported,
      isLedm
    }),
    [
      productNumber,
      discoveryTree,
      factoryModelName,
      init,
      isLedm,
      isSupported,
      liveUiVersion,
      modelName,
      oobeManifestTree,
      productFamily
    ]
  )

  return (
    <PrinterContext.Provider value={printerState}>
      {props.children}
    </PrinterContext.Provider>
  )
}

export default PrinterProvider
