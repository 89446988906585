import React, { useContext } from 'react'
import { Route, Routes, useMatch } from 'react-router-dom'
import LanguageCountry from './LanguageCountry'
import { ConfigContext } from '../../../src/store/ConfigContext'

const App = () => {
  const { localization } = useContext(ConfigContext)
  const match = useMatch('/:country/:language/*')
  return (
    <Routes>
      <Route
        path={`${
          localization.enabled && match ? match.pathnameBase : ''
        }/language-country/*`}
        element={<LanguageCountry />}
      />
    </Routes>
  )
}

export default App
