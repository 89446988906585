import useCdm from './useCdm'
import useLedm from './useLedm'
import { LoggingContext } from '../store/LoggingContext'
import { useContext } from 'react'
import {
  getDeviceSetupHref,
  getSystemIdentityHref,
  getLoadMainTrayActionStatus,
  getPackageInformationType,
  getInstallPhaActionStatus,
  getPrintState,
  getPrintAlignmentErrorMessage,
  filterCalibrationAlerts,
  getFillInkTanksActionStatus,
  getScanState,
  getCartridgeState,
  filterCartridgeAlerts
} from '../utils/cdm/helpers'
import {
  getOobeManifestHref,
  getProductConfigDynHref,
  getLoadMainTrayActionStatus as getLoadMainTrayActionStatusLedm,
  getInstallPhaActionStatus as getInstallPhaActionStatusLedm,
  getSemiCalibrationPrintStatus,
  getFillInkTanksActionStatus as getFillInkTanksActionStatusLedm,
  getSemiCalibrationScanStatus,
  getInsertInkStatus,
  getOobeStatusHref,
  getOobeDeviceInfoHref,
  getOobeConfigHref,
  getLanguageConfigStatus,
  getCountryConfigStatus,
  getRemoveWrapStatus,
  getAutoCalibrationStatus
} from '../utils/ledm/helpers'
import {
  ALIGNMENT_PAGE,
  CALIBRATION,
  SUPPLY_ERROR,
  LIVE_UI_VERSION_1,
  LIVE_UI_VERSION_2,
  PEN_ALIGNMENT_SEMI_AUTO,
  STATUS_FAILED,
  STATUS_INTERVENTION,
  STATUS_NOT_STARTED,
  STATUS_PENDING,
  STATUS_PROCESSING,
  SETUP_OOBE_STAGE_PAYLOAD
} from '../config/constants'
import { isDHPError } from '../utils/helpers'

const usePrinter = (
  isLedm = false,
  discoveryTree = null,
  oobeManifestTree = null
) => {
  const _discoveryTree = JSON.parse(discoveryTree)
  const _oobeManifestTree = JSON.parse(oobeManifestTree)
  const {
    fetch: cdmFetch,
    getLocale: getLocaleCdm,
    fetchServicesDiscovery,
    patchDeviceSetup,
    patchSystemConfig,
    fetchPackageInformation,
    fetchSuppliesPublic,
    fetchReportsPrint,
    fetchCalibrationAlerts,
    patchReportsPrint,
    fetchCalibrationCapabilities,
    patchCalibrationCapabilities,
    fetchCartridgeSetup,
    fetchCartridgeSetupAlerts: cdmFetchCartridgeSetupAlerts,
    patchCartridgeSetupState: cdmPatchCartridgeSetupState
  } = useCdm()
  const {
    getLocale: getLocaleLedm,
    patchLocale: patchLocaleLedm,
    fetch: ledmFetch,
    fetchDiscoveryTree,
    startCalibration: startCalibrationLedm,
    put,
    fetchInkAlerts: fetchInkAlertsLedm,
    fetchCalibrationAlerts: fetchCalibrationAlertsLedm
  } = useLedm()
  const { Logger } = useContext(LoggingContext)
  const _fetch = (href, handleError = true, useCache = false) => {
    return isLedm
      ? ledmFetch(href, handleError, useCache)
      : cdmFetch(href, handleError, useCache)
  }

  const fetchDiscovery = async () => {
    const resp = isLedm
      ? await fetchDiscoveryTree()
      : await fetchServicesDiscovery()
    if (!resp?.body?.data) return null
    return resp.body.data
  }

  const fetchDeviceInfo = async () => {
    const result = {
      productNumber: '',
      modelName: ''
    }
    const href = isLedm
      ? getProductConfigDynHref(_discoveryTree)
      : getSystemIdentityHref(_discoveryTree)
    const resp = await _fetch(href, true, true)
    if (!resp?.body?.data) return result
    if (isLedm) {
      result.productNumber =
        resp.body.data?.['prdcfgdyn2:ProductConfigDyn']?.[
          'prdcfgdyn:ProductInformation'
        ]?.['dd:ProductNumber']
      result.modelName =
        resp.body.data?.['prdcfgdyn2:ProductConfigDyn'][
          'prdcfgdyn:ProductInformation'
        ]['dd:MakeAndModelBase']
    } else {
      result.productNumber = resp.body.data?.['productNumber']
      result.modelName = resp.body.data?.['makeAndModel']['base']
    }
    return result
  }

  const fetchOobeManifest = async () => {
    if (!isLedm) {
      Logger.warn('CDM does not support OOBE manifest')
      return null
    }
    const resp = await _fetch(getOobeManifestHref(_discoveryTree), true, true)
    if (!resp?.body?.data) return null
    return resp.body.data
  }

  const fetchStatus = async () => {
    const href = isLedm
      ? getOobeStatusHref(_oobeManifestTree)
      : getDeviceSetupHref(_discoveryTree)
    const resp = await _fetch(href)
    if (!resp?.body?.data) return null
    return resp.body.data
  }

  const fetchLiveUiVersion = async () => {
    if (!isLedm) {
      Logger.warn('CDM does not support OOBE Device Info')
      return LIVE_UI_VERSION_2
    }
    const resp = await _fetch(
      getOobeDeviceInfoHref(_oobeManifestTree),
      true,
      true
    )
    try {
      return resp.body.data['ob:OOBEDeviceInfo']['ob:LiveUIVersion']
    } catch {
      return LIVE_UI_VERSION_1
    }
  }

  const fetchLoadMainTrayStatus = async () => {
    const resp = await fetchStatus()
    if (isLedm) {
      return getLoadMainTrayActionStatusLedm(resp)
    }
    return getLoadMainTrayActionStatus(resp)
  }

  const fetchPrintheadStatus = async () => {
    if (isLedm) {
      const resp = await fetchStatus()
      return getInstallPhaActionStatusLedm(resp)
    }
    const resp = await fetchSuppliesPublic()
    return getInstallPhaActionStatus(resp?.body?.data)
  }

  const fetchFillInkTanksStatus = async () => {
    if (isLedm) {
      const resp = await fetchStatus()
      return getFillInkTanksActionStatusLedm(resp)
    }
    const resp = await fetchSuppliesPublic()
    return getFillInkTanksActionStatus(resp?.body?.data)
  }

  const fetchRemoveWrapStatus = async () => {
    if (!isLedm) {
      Logger.warn('CDM does not support remove wrap stage')
      return STATUS_PENDING
    }

    const resp = await fetchStatus()
    return getRemoveWrapStatus(resp)
  }

  const patchStatus = async (payload) => {
    if (isLedm) {
      Logger.warn('LEDM does not support patch status')
      return { statusCode: 200 }
    }
    return patchDeviceSetup(getDeviceSetupHref(_discoveryTree), payload)
  }

  const patchSetupOOBEStage = async (stage) => {
    if (!isLedm) {
      Logger.warn('CDM does not support patch SetupOOBEStage')
      return null
    }
    const payload = SETUP_OOBE_STAGE_PAYLOAD.replace('$stage', stage)
    return put(getOobeConfigHref(_oobeManifestTree), payload, false)
  }

  const getLocale = async () => {
    return isLedm ? await getLocaleLedm(_discoveryTree) : await getLocaleCdm()
  }

  const patchLocale = async (language, country, handleError = true) => {
    return isLedm
      ? await patchLocaleLedm(_discoveryTree, language, country, handleError)
      : await patchSystemConfig(language, country, handleError)
  }

  const hasPackageInformationForType = async (type) => {
    if (isLedm) {
      Logger.warn('LEDM does not support package information')
      return false
    }
    const resp = await fetchPackageInformation()
    return getPackageInformationType(resp?.body?.data, type)
  }

  const fetchAutoCalibrationStatus = async () => {
    const result = {
      printState: null,
      alertCategory: null,
      cartridges: []
    }
    if (!isLedm) {
      Logger.warn('CDM does not support Auto Calibration')
      return STATUS_PENDING
    }
    const response = await fetchStatus()
    result.printState = getAutoCalibrationStatus(response)
    if (result.printState === STATUS_FAILED) {
      const { category, cartridges = [] } = await fetchCalibrationAlertsLedm(
        _discoveryTree
      )
      result.alertCategory = category
      result.cartridges = cartridges
    }
    return result
  }

  const fetchSemiCalibrationPrintStatus = async () => {
    const result = {
      printState: null,
      scanState: null,
      errorMessage: null,
      alertCategory: null,
      cartridges: []
    }
    if (isLedm) {
      const response = await fetchStatus()
      result.printState = getSemiCalibrationPrintStatus(response)
      result.scanState = getSemiCalibrationScanStatus(response)
      if (result.printState === STATUS_FAILED) {
        result.printState = STATUS_INTERVENTION
        const { category, cartridges = [] } = await fetchCalibrationAlertsLedm(
          _discoveryTree
        )
        result.alertCategory = category
        result.cartridges = cartridges
      }
    } else {
      const response = await fetchReportsPrint()
      result.printState = getPrintState(response?.body?.data)
      if (result.printState === STATUS_FAILED) {
        result.errorMessage = getPrintAlignmentErrorMessage(
          response?.body?.data?.failureReason
        )
      }
      if (result.printState === STATUS_INTERVENTION) {
        const responseAlerts = await fetchCalibrationAlerts(
          response?.body?.data
        )
        const { category, cartridges } = filterCalibrationAlerts(
          responseAlerts?.body?.data?.alerts
        )
        result.alertCategory = category
        result.cartridges = cartridges
      }
    }
    return result
  }

  const printAlignmentPage = async () => {
    if (isLedm) {
      let response = await startCalibrationLedm(_discoveryTree)
      isDHPError(response) &&
        (response = await startCalibrationLedm(_discoveryTree))
      return response
    }
    let response = await patchReportsPrint(ALIGNMENT_PAGE, STATUS_PROCESSING)
    isDHPError(response) &&
      (response = await patchReportsPrint(ALIGNMENT_PAGE, STATUS_PROCESSING))
    return response
  }

  const fetchSemiCalibrationScanStatus = async () => {
    if (isLedm) {
      return await fetchSemiCalibrationPrintStatus()
    }
    const response = await fetchCalibrationCapabilities()
    const state = getScanState(response?.body?.data)
    if (state === STATUS_NOT_STARTED) {
      await patchCalibrationCapabilities(
        PEN_ALIGNMENT_SEMI_AUTO,
        CALIBRATION,
        STATUS_PENDING
      )
    }
    return { scanState: state }
  }

  const fetchCartridgeStatus = async () => {
    const result = {
      state: null,
      alertCategory: null,
      cartridges: []
    }
    if (isLedm) {
      const response = await fetchStatus()
      result.state = getInsertInkStatus(response)
      if (result.state === STATUS_FAILED) {
        result.state = SUPPLY_ERROR
        const { category, cartridges = [] } = await fetchInkAlertsLedm(
          _discoveryTree
        )
        result.cartridges = cartridges
        result.alertCategory = category
      }
    } else {
      let response = await fetchCartridgeSetup()
      result.state = getCartridgeState(response?.body?.data)
      if (result.state === SUPPLY_ERROR) {
        response = await cdmFetchCartridgeSetupAlerts(response?.body?.data)
        const { category, cartridges } = filterCartridgeAlerts(
          response?.body?.data?.alerts
        )
        result.alertCategory = category
        result.cartridges = cartridges
      }
    }
    return result
  }

  const patchCartridgeSetupState = (state) => {
    if (isLedm) {
      Logger.warn('LEDM does not support patching Cartridge Setup State')
      return
    }
    return cdmPatchCartridgeSetupState(state)
  }

  const fetchLanguageCountryStatus = async () => {
    const result = {
      languageStatus: null,
      countryStatus: null
    }

    if (!isLedm) {
      Logger.warn('CDM does not support language country status')
      return result
    }

    const response = await fetchStatus()
    result.languageStatus = getLanguageConfigStatus(response)
    result.countryStatus = getCountryConfigStatus(response)
    return result
  }

  return {
    fetchDiscovery,
    fetchDeviceInfo,
    fetchOobeManifest,
    fetchStatus,
    fetchLoadMainTrayStatus,
    fetchPrintheadStatus,
    fetchLiveUiVersion,
    patchStatus,
    patchSetupOOBEStage,
    getLocale,
    patchLocale,
    hasPackageInformationForType,
    fetchSemiCalibrationPrintStatus,
    printAlignmentPage,
    fetchFillInkTanksStatus,
    fetchSemiCalibrationScanStatus,
    fetchCartridgeStatus,
    patchCartridgeSetupState,
    fetchLanguageCountryStatus,
    fetchRemoveWrapStatus,
    fetchAutoCalibrationStatus
  }
}

export default usePrinter
