import ar_SA from './ar_SA.json'
import bg_BG from './bg_BG.json'
import ca_ES from './ca_ES.json'
import cs_CZ from './cs_CZ.json'
import da_DK from './da_DK.json'
import de_DE from './de_DE.json'
import el_GR from './el_GR.json'
import en_US from './en_US.json'
import es_ES from './es_ES.json'
import et_EE from './et_EE.json'
import fi_FI from './fi_FI.json'
import fr_FR from './fr_FR.json'
import fr_CA from './fr_CA.json'
import he_IL from './he_IL.json'
import hr_HR from './hr_HR.json'
import hu_HU from './hu_HU.json'
import id_ID from './id_ID.json'
import it_IT from './it_IT.json'
import ja_JP from './ja_JP.json'
import ko_KR from './ko_KR.json'
import lt_LT from './lt_LT.json'
import lv_LV from './lv_LV.json'
import nb_NO from './nb_NO.json'
import nl_NL from './nl_NL.json'
import pl_PL from './pl_PL.json'
import pt_BR from './pt_BR.json'
import pt_PT from './pt_PT.json'
import ro_RO from './ro_RO.json'
import ru_RU from './ru_RU.json'
import sk_SK from './sk_SK.json'
import sl_SI from './sl_SI.json'
import sv_SE from './sv_SE.json'
import th_TH from './th_TH.json'
import tr_TR from './tr_TR.json'
import zh_CN from './zh_CN.json'
import zh_TW from './zh_TW.json'

export default {
  ar_SA: ar_SA,
  bg_BG: bg_BG,
  ca_ES: ca_ES,
  cs_CZ: cs_CZ,
  da_DK: da_DK,
  de_DE: de_DE,
  el_GR: el_GR,
  en_US: en_US,
  es_ES: es_ES,
  et_EE: et_EE,
  fi_FI: fi_FI,
  fr_CA: fr_CA,
  fr_FR: fr_FR,
  he_IL: he_IL,
  hr_HR: hr_HR,
  hu_HU: hu_HU,
  id_ID: id_ID,
  it_IT: it_IT,
  ja_JP: ja_JP,
  ko_KR: ko_KR,
  lt_LT: lt_LT,
  lv_LV: lv_LV,
  nb_NO: nb_NO,
  nl_NL: nl_NL,
  pl_PL: pl_PL,
  pt_BR: pt_BR,
  pt_PT: pt_PT,
  ro_RO: ro_RO,
  ru_RU: ru_RU,
  sk_SK: sk_SK,
  sl_SI: sl_SI,
  sv_SE: sv_SE,
  th_TH: th_TH,
  tr_TR: tr_TR,
  zh_CN: zh_CN,
  zh_TW: zh_TW
}
export const localeFallback = {
  zh_HK: 'zh_TW',
  zh_MO: 'zh_TW'
}

export const languageFallback = {
  ar: 'ar_SA',
  bg: 'bg_BG',
  ca: 'ca_ES',
  cs: 'cs_CZ',
  da: 'da_DK',
  de: 'de_DE',
  el: 'el_GR',
  en: 'en_US',
  es: 'es_ES',
  et: 'et_EE',
  fi: 'fi_FI',
  fr: 'fr_FR',
  he: 'he_IL',
  hr: 'hr_HR',
  hu: 'hu_HU',
  id: 'id_ID',
  it: 'it_IT',
  ja: 'ja_JP',
  ko: 'ko_KR',
  lt: 'lt_LT',
  lv: 'lv_LV',
  nb: 'nb_NO',
  nl: 'nl_NL',
  pl: 'pl_PL',
  pt: 'pt_PT',
  ro: 'ro_RO',
  ru: 'ru_RU',
  sk: 'sk_SK',
  sl: 'sl_SI',
  sv: 'sv_SE',
  th: 'th_TH',
  tr: 'tr_TR',
  zh: 'zh_CN',
  nn: 'nb_NO',
  no: 'nb_NO',
  kk: 'en_US',
  sr: 'en_US',
  uk: 'en_US',
  vi: 'en_US'
}
