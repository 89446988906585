import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo
} from 'react'
import { ConfigContext } from '../../src/store/ConfigContext'
import { ErrorContext } from '../../src/store/ErrorContext'
import { generateError } from '../../src/utils/helpers'
import {
  EMPTY_FUNCTION,
  DEVICE_NOT_SUPPORTED,
  DEVICE_LEDM_ONLY,
  DHP_DISCOVERY_TREE_EMPTY_ERROR,
  DEVICE_CDM_ONLY,
  DHP_OOBE_MANIFEST_TREE_EMPTY_ERROR,
  DHP_DEVICE_STATUS_EMPTY_ERROR
} from '../../src/config/constants'
import usePrinter from '../../src/hooks/usePrinter'
import { SERVICE_ID } from '../config/constants'
import { LoggingContext } from '../../src/store/LoggingContext'

export const PrinterContext = React.createContext({
  discoveryTree: null,
  init: EMPTY_FUNCTION,
  isSupported: null,
  isLedm: null,
  liveUiVersion: null,
  status: null
})

const PrinterProvider = (props) => {
  const { sessionContext } = useContext(ConfigContext)
  const { setError, error } = useContext(ErrorContext)
  const [discoveryTree, setDiscoveryTree] = useState(null)
  const [resourceError, setResourceError] = useState(null)
  const [liveUiVersion, setLiveUiVersion] = useState(null)
  const [fetchedLiveUiVersion, setFetchedLiveUiVersion] = useState(false)
  const [status, setStatus] = useState(null)
  const [fetchedStatus, setFetchedStatus] = useState(false)
  const [oobeManifestTree, setOobeManifestTree] = useState(null)
  const [fetchedManifest, setFetchedManifest] = useState(false)
  const { Logger } = useContext(LoggingContext)

  const liveUiCapability =
    sessionContext?.device?.liveUiCapability || DEVICE_NOT_SUPPORTED
  const isSupported =
    liveUiCapability === DEVICE_CDM_ONLY ||
    liveUiCapability === DEVICE_LEDM_ONLY
  const isLedm = liveUiCapability === DEVICE_LEDM_ONLY

  const { fetchDiscovery, fetchLiveUiVersion, fetchOobeManifest, fetchStatus } =
    usePrinter(isLedm, discoveryTree, oobeManifestTree)

  /* Hook responsible for setting generic error in case unable to pull required
   * resources */
  useEffect(() => {
    if (resourceError && !error) {
      setError(generateError({ errorType: resourceError }, SERVICE_ID))
    }
  }, [resourceError, error, setError])

  /* Async hook callback for fetching device status */
  const _fetchStatus = useCallback(async () => {
    const _status = await fetchStatus()
    if (!_status) {
      Logger.warn('Unable to fetch status')
      setResourceError(DHP_DEVICE_STATUS_EMPTY_ERROR)
      return
    }
    setStatus(_status)
  }, [fetchStatus, Logger])

  /* Hook responsible for fetching either CDM Device Setup Status once CDM services discovery
   * is available or LEDM OOBE Status once OOBE manifest tree is available */
  useEffect(() => {
    if (
      !fetchedStatus &&
      !status &&
      ((!isLedm && discoveryTree) || oobeManifestTree)
    ) {
      setFetchedStatus(true)
      _fetchStatus()
    }
  }, [
    discoveryTree,
    oobeManifestTree,
    _fetchStatus,
    status,
    fetchedStatus,
    isLedm
  ])

  /* Async hook callback for fetching OOBE manifest tree */
  const fetchManifest = useCallback(async () => {
    const tree = await fetchOobeManifest()
    if (!tree) {
      Logger.warn('Unable to fetch OOBE manifest tree')
      setResourceError(DHP_OOBE_MANIFEST_TREE_EMPTY_ERROR)
      return
    }
    setOobeManifestTree(JSON.stringify(tree))
  }, [fetchOobeManifest, Logger])

  /* Hook responsible for fetching OOBE manifest tree for LEDM only once discovery tree is available */
  useEffect(() => {
    if (!fetchedManifest && !oobeManifestTree && discoveryTree && isLedm) {
      setFetchedManifest(true)
      fetchManifest()
    }
  }, [
    fetchedManifest,
    discoveryTree,
    oobeManifestTree,
    isLedm,
    fetchManifest,
    Logger
  ])

  /* Async hook callback for determining live ui version */
  const checkLiveUiVersion = useCallback(async () => {
    setLiveUiVersion(await fetchLiveUiVersion())
  }, [fetchLiveUiVersion])

  /* Hook responsible for determining live ui version once CDM services discovery
   * or LEDM OOBE manifest tree is available */
  useEffect(() => {
    if (
      !fetchedLiveUiVersion &&
      !liveUiVersion &&
      ((!isLedm && discoveryTree) || oobeManifestTree)
    ) {
      setFetchedLiveUiVersion(true)
      checkLiveUiVersion()
    }
  }, [
    discoveryTree,
    oobeManifestTree,
    fetchedLiveUiVersion,
    liveUiVersion,
    checkLiveUiVersion,
    isLedm,
    Logger
  ])

  /* Init method is responsible for fetching discovery tree. This will trigger
   * other dependent hooks */
  const init = useCallback(async () => {
    if (!isSupported) return
    const _discoveryTree = await fetchDiscovery()
    if (!_discoveryTree) {
      Logger.warn('Unable to fetch discovery tree')
      setResourceError(DHP_DISCOVERY_TREE_EMPTY_ERROR)
      return
    }
    setDiscoveryTree(JSON.stringify(_discoveryTree))
  }, [fetchDiscovery, isSupported, Logger])

  const printerState = useMemo(
    () => ({
      discoveryTree,
      init,
      isSupported,
      isLedm,
      liveUiVersion,
      status
    }),
    [discoveryTree, init, isSupported, isLedm, liveUiVersion, status]
  )

  return (
    <PrinterContext.Provider value={printerState}>
      {props.children}
    </PrinterContext.Provider>
  )
}

export default PrinterProvider
