import React, { lazy, useContext } from 'react'
import { Route, Routes } from 'react-router-dom'
import PortalEntry from '../../pages/PortalEntry'
import { WithSuspense } from '../../../src/utils/helpers'
import { ErrorContext } from '../../../src/store/ErrorContext'

const Completed = lazy(() =>
  import(/* webpackChunkName: "page-completed" */ '../../pages/Completed')
)

const GenericError = lazy(() =>
  import(
    /* webpackChunkName: "page-generic-error" */ '../../pages/GenericError'
  )
)

const LanguageCountrySelector = lazy(() =>
  import(
    /* webpackChunkName: "page-lang-country-selector" */ '../../pages/LanguageCountrySelector'
  )
)

const LanguageCountry = () => {
  const { error } = useContext(ErrorContext)

  return (
    <>
      {error && (
        <WithSuspense component={GenericError} props={{ error: error }} />
      )}
      <Routes>
        <Route
          path="languageCountrySelector"
          element={<WithSuspense component={LanguageCountrySelector} />}
        />
        <Route
          path="completed"
          element={<WithSuspense component={Completed} />}
        />
        {/* Portal entry path */}
        <Route exact path="/" element={<PortalEntry />} />
      </Routes>
    </>
  )
}
export default LanguageCountry
